import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64e2257a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer-bar-left" }
const _hoisted_2 = { class: "footer-bar-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["footer-bar footer-bar-min-width", _ctx.menuCollapsed ? 'footer-bar-max-width':'footer-bar-min-width'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "left", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "right", {}, undefined, true)
    ])
  ], 2))
}