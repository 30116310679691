
import store from "@/store";
import { computed, defineComponent } from "vue";

export default defineComponent({
  setup() {
    const menuCollapsed = computed(() => {
      return store.getters.getSidebarCollapsed;
    });
    return { menuCollapsed };
  },
});
